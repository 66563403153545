// set component for maintenance page

import React from 'react'
import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

const Maintenance = () => {
    const navigate = useNavigate()
    return (
        <Result
            status="500"
            title="Servidor en mantenimiento"
            subTitle="Por favor intenta más tarde."
        />
    )
}

export default Maintenance


